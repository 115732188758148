import "@babel/polyfill";
import 'whatwg-fetch'

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from "./App";

import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
	<Router>
		<Route component={ App } />
	</Router>, document.getElementById('root'));

registerServiceWorker();
